/*-------------------------
   Brand
-------------------------*/
.brand-wrap-area {
    padding: 90px 0;
}
.brand-grid {
    background: white;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 10px 0;
    img {
        opacity: 0.3;
        transition: var(--transition);
        width: 200px;
        margin: 0 auto;
        display: block;
    }
    &:hover, &.active {
        img {
            opacity: 1;
            filter: grayscale(0);
        }
    }
}

