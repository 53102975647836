/*---------------------------------------------
Template Name: Abstrak
Template URL: https://new.axilthemes.com/demo/react/abstrak/
Description: Digital Agency React JS Template.
Author: Axilthemes
Author URL: https://axilthemes.com/
Version: 1.0.0

===============================================   
STYLESHEET INDEXING
|
|___ Default Styles
|	|___ Variables
|	|___ Reset Styels
|	|___ Typography
|	|___ Forms Styles
|	|___ Helper Classes 
|
|___ Elements Styles
|	|___ Back To Top
|	|___ Breadcrumb
|	|___ Button
|	|___ Pagination 
|	|___ Section Heading
|	|___ Slick 
|
|___Template Styles
|	|___ About Us
|	|___ Banner
|	|___ Brand
|	|___ Call To Action
|	|___ Case Study
|	|___ Contact
|	|___ Counter Up
|	|___ FAQ
|	|___ Pricing
|	|___ Process
|	|___ Project
|	|___ Service
|	|___ Team
|	|___ Testimonial
|	|___  Why Choose
|	|___  404 Error
|
|___Blocks Styles
|	|___ Header Styles
|	|___ Blog Styles
|	|___ Footer Styles
|
|___Dark Style
|
|___Spacing
|
|___ END STYLESHEET INDEXING

--------------------------------------------*/
.backWhte{background-color: white!important}
.marginTopO{margin-top:20px!important}
/*=======================================================================
1. Default Styles
=========================================================================*/
@import "default/variable";
@import "default/reset";
@import "default/typography";
@import "default/form";
@import "default/classes";

/*=======================================================================
2. Elements Styles
=========================================================================*/
@import "elements/animate";
@import "elements/back-top";
@import "elements/breadcrumb";
@import "elements/button";
@import "elements/pagination";
@import "elements/section-heading";
@import "elements/slick";

/*=======================================================================
3. Template Styles
=========================================================================*/
@import "template/about";
@import "template/banner";
@import "template/brand";
@import "template/call-to-action";
@import "template/case-study";
@import "template/contact";
@import "template/counterup";
@import "template/faq";
@import "template/pricing";
@import "template/process";
@import "template/project";
@import "template/service";
@import "template/team";
@import "template/testimonial";
@import "template/why-choose";
@import "template/common-pages";
@import "template/splash.scss";
@import "template/onepage";

/*=======================================================================
4.Blocks Styles 
=========================================================================*/
@import "header/header";
@import "blog/blog";
@import "blog/widget";
@import "footer/footer";

/*=======================================================================
5. Dark Style 
=========================================================================*/
@import "dark-style/dark-version";

/*=======================================================================
6. Spacing 
=========================================================================*/
@import "default/spacing";


.coa {
    div{
        z-index: 2;
    }
}

.active-dark-mode{
    #battery,.sc-fEXmlR{background-color: #222;}
}

.mobileNo{
    
  @media #{$md-layout} {
    display: none;
}
@media #{$sm-layout} {
   display: none;
}
}



.large-thumb img{
    width: 250px;
    max-height: 600px;
    object-fit: contain;
}